import i18next from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
    de: {
        translations: require("../locales/de/translations.json"),
        local: require("../locales/de/local.json"),
        shared: require("../shareact/locales/de/shared.json"),
    },
}

i18next.use(initReactI18next).init({
    resources: resources,
    lng: process.env.LANG,
    ns: ["translations", "local", "shared"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: true,
    },
})

export default i18next
