import { AuthProvider } from "../shareact/context/auth"
import { DateProvider } from "../context/date-context"
import { LoadingProvider } from "../shareact/context/loading"
import { LocalesProvider } from "../shareact/context/locales"
import { PopperProvider } from "../shareact/context/popper"
import React from "react"
import { RuleProvider } from "../context/rule-context"

const WrapPage = ({ element }) => {
    return (
        <>
            <PopperProvider>
                <LocalesProvider language={process.env.DEFAULT_LANGUAGE}>
                    <LoadingProvider>
                        <AuthProvider>
                            <RuleProvider>
                                <DateProvider>{element}</DateProvider>
                            </RuleProvider>
                        </AuthProvider>
                    </LoadingProvider>
                </LocalesProvider>
            </PopperProvider>
        </>
    )
}

export default WrapPage
