import React, { useState } from "react"

const DateContext = React.createContext([[null, null], () => {}])

const DateProvider = ({ children }) => {
    const start = new Date()
    start.setDate(1)
    const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
    const date = useState([start, end])

    return <DateContext.Provider value={date}>{children}</DateContext.Provider>
}

export { DateContext, DateProvider }
