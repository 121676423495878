import React, { useEffect, useState } from "react"

import { useCommissionRules } from "../hooks/use-commission-rule"
import { useQueryCustomer } from "../shareact/hooks/customer/use-query-customer"

const RuleContext = React.createContext([null, () => {}])

const RuleProvider = ({ children }) => {
    const [ruleId, setRuleId] = useState(null)
    const { data: commissionRulesData } = useCommissionRules()
    const rules = commissionRulesData?.rules
    const { data: customerData } = useQueryCustomer()

    useEffect(() => {
        if (rules && customerData && !ruleId) {
            setRuleId((rules.find(r => customerData?.number === r.number) || rules[0])?.id)
        }
    }, [customerData, rules, ruleId])

    return <RuleContext.Provider value={[ruleId, setRuleId]}>{children}</RuleContext.Provider>
}

export { RuleContext, RuleProvider }
