import "./src/styles/global.css"
import "./src/shareact/styles/icon-font.css"
import "./src/i18n/config"
import "./src/styles/toast.css"
import "intersection-observer" // polyfill for IntersectionObserver

import Tracker from '@openreplay/tracker/cjs';
import trackerAssist from '@openreplay/tracker-assist';
import wrapPageElement from "./src/utils/wrap-page"
import wrapRootElement from "./src/utils/wrap-root"

/* global exponea */

const onRouteUpdate = ({ location }) => {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        exponea.track("page_visit", {
            location: location.href,
            path: location.pathname,
        })
    }, 50)

}

const onClientEntry = () => {
    if (typeof window.tracker !== "undefined" || process.env.NODE_ENV === "development") return

    window.tracker = new Tracker({
        projectKey: process.env.OPENREPLAY_KEY,
        ingestPoint: process.env.OPENREPLAY_URL,
        obscureTextEmails: false,
        obscureInputEmails: false,
        captureIFrames: false,
        __DISABLE_SECURE_MODE: window.location.hostname === "localhost",
    })
    window.tracker.use(trackerAssist({}));
    window.tracker.start();
}

export { onRouteUpdate, onClientEntry, wrapPageElement, wrapRootElement }
