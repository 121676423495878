import client from "../shareact/utils/client"
import { useClientState } from "../shareact/context/client"
import { useQueryLoading } from "../shareact/hooks/use-query-loading"

const useCommissionRules = () => {
    const { customerWeakToken } = useClientState()
    return useQueryLoading(["commission-rules"], () => client.getCommissionRules(), {
        enabled: !!customerWeakToken,
    })
}

const useCommissionRuleById = id => {
    return useQueryLoading(["commission-rule-by-id", id], () => client.getCommissionRule(id), {
        enabled: !!id,
    })
}

export { useCommissionRules, useCommissionRuleById }
